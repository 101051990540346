require("styles/styles.sass")

export const onRouteUpdate = ({ location }) => {
  document.getElementsByTagName("body")[0].style.backgroundColor = ""
  document.getElementById("___gatsby").style.backgroundColor = ""

  if (!!location.hash) {
    const hash_target = document.getElementById(
      location.hash.slice(1)?.split("&")?.[0]
    )
    if (!hash_target) {
      return
    }
    if (location.hash?.startsWith("#demosearch-box")) {
      window.scrollTo({ top: 200, behavior: "smooth" })
      return
    }
    setTimeout(() => {
      hash_target.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 150)
  }
}
